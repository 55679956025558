import React, {useState, useCallback} from 'react';
import {Styles, View, GestureView, Text, Image} from 'react-ult';
import {useDispatch} from 'react-redux';
import {Dark} from 'theme';
import {utils, events} from 'content';

export interface Props {
  name: string;
  type: string;
  path: string;
  touch: boolean;
  selected: boolean;
}

export function ContentListItem(props: Props) {
  const dispatch = useDispatch();
  const [stateDrop, setDrop] = useState(0);
  const [stateDrag, setDrag] = useState(false);
  const select = useCallback(events.onSelect(dispatch, props.path), [dispatch, props.path]);
  const menu = useCallback(events.onMenu(dispatch, props.path), [dispatch, props.path]);
  const drop = useCallback(events.onDrop(setDrop), [setDrop]);
  const dragEnter = useCallback(events.onDragEnter(setDrop, stateDrop), [setDrop, stateDrop]);
  const dragLeave = useCallback(events.onDragLeave(setDrop, stateDrop), [setDrop, stateDrop]);
  const dragStart = useCallback(events.onDragStart(setDrag, props.path), [setDrag, props.path]);
  const dragEnd = useCallback(events.onDragEnd(setDrag), [setDrag]);
  const description = utils.getDescription(props.type);
  const icon = utils.getIcon(props.type);
  const variants = {
    root: [
      styles.root,
      !props.touch && props.selected && styles.selected,
      props.touch && styles.touch,
      stateDrag && styles.dragging,
      stateDrop > 0 && styles.dropping,
    ],
    title: [
      props.touch ? styles.titleTouch : styles.title,
      props.selected && styles.active,
    ],
    icon: props.type === 'folder'
      ? props.touch ? styles.folderTouch : styles.folder
      : props.touch ? styles.fileTouch : styles.file,
  };

  return (
    <View
      style={variants.root}
      onPress={select}
      onDrop={drop}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDragStart={dragStart}
      onDragEnd={dragEnd}>
      <GestureView
        style={styles.inner}
        onContextMenu={menu}>
        <View style={styles.info}>
          <Text style={variants.title}>{props.name}</Text>
          {props.touch &&
            <Text style={styles.description}>{description}</Text>
          }
        </View>
      </GestureView>
    </View>
  );
}

export const styles = {
  root: Styles.View({
    flex: 0,
    flexDirection: 'row',
    height: 32, // 36
    backgroundColor: Dark.ContentListFill,
  }),
  dragging: Styles.View({
    backgroundColor: Dark.ContentListItemSelectedFill,
  }),
  dropping: Styles.View({
    opacity: 1,
    backgroundColor: Dark.ContentListItemDropFill,
  }),
  selected: Styles.View({
    backgroundColor: Dark.ContentListItemSelectedFill,
  }),
  active: Styles.Text({
    color: Dark.ContentListItemTitleActive,
  }),
  touch: Styles.View({
    height: 60,
  }),
  inner: Styles.View({
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  }),
  info: Styles.View({
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    marginHorizontal: 12,
  }),
  title: Styles.Text({
    alignSelf: 'center',
    fontSize: 14,
    color: Dark.ContentListItemTitle,
  }),
  titleTouch: Styles.Text({
    fontSize: 14,
    color: Dark.ContentListItemTitle,
  }),
  description: Styles.Text({
    fontSize: 11,
    marginTop: 4,
    color: Dark.ContentListItemDescription,
  }),
  folder: Styles.Image({
    alignSelf: 'center',
    width: 20,
    height: 20,
    marginHorizontal: 10,
  }),
  icon: Styles.View({
    justifyContent: 'center',
  }),
  folderTouch: Styles.Image({
    alignSelf: 'center',
    width: 42,
    height: 42,
    marginHorizontal: 14,
  }),
  file: Styles.Image({
    alignSelf: 'center',
    width: 24,
    height: 24,
    marginHorizontal: 8,
  }),
  fileTouch: Styles.Image({
    alignSelf: 'center',
    width: 42,
    height: 42,
    marginHorizontal: 14,
  }),
};
