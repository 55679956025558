import React from 'react';

export function ContentPreviewApp() {
  return (
    <iframe
      name='app'
      src='http://localhost:3000/'
      style={{
        width: '411px',
        height: '731px',
        border: 0,
      }}>
    </iframe>
  );
}
