import React, {useState, useCallback} from 'react';
import {Styles, View, GestureView, Text, Image} from 'react-ult';
import {useDispatch} from 'react-redux';
import {Dark} from 'theme';
import {events} from 'content';

export interface Props {
  index: number;
  title: string;
  type: string;
  icon: string;
  path: string;
  touch: boolean;
  active: boolean;
  temporary?: boolean;
}

export function ContentPreviewHeaderTab(props: Props) {
  const [dragstate, setDrag] = useState(false);
  const [dropstate, setDrop] = useState(0);
  const dispatch = useDispatch();
  const focus = useCallback(events.onFocus(dispatch, props.index), [dispatch, props.index]);
  const close = useCallback(events.onClose(dispatch, props.index), [dispatch, props.index]);
  const preview = useCallback(events.onPreview(dispatch, props.path), [dispatch, props.path]);
  const menu = useCallback(events.onMenu(dispatch, props.path), [dispatch, props.path]);
  const drop = useCallback(events.onDrop(setDrop), [setDrop]);
  const dragEnter = useCallback(events.onDragEnter(setDrop, dropstate), [setDrop, dropstate]);
  const dragLeave = useCallback(events.onDragLeave(setDrop, dropstate), [setDrop, dropstate]);
  const dragStart = useCallback(events.onDragStart(setDrag, props.path), [setDrag, props.path]);
  const dragEnd = useCallback(events.onDragEnd(setDrag), [setDrag]);
  const closeImage = require('content/ui/assets/icons/navigation/close.svg');
  const variants = {
    root: [
      styles.root,
      props.active && styles.active,
      props.touch && styles.touch,
      dragstate && styles.dragging,
      dropstate > 0 && styles.dropping,
    ],
    title: [
      styles.title,
      props.touch && styles.titleTouch,
      props.temporary && styles.temporary,
    ],
    icon: props.type === 'folder'
      ? styles.iconFolder
      : styles.icon,
  };

  return (
    <View
      style={variants.root}
      onPress={!props.active ? focus : undefined}
      onDrop={drop}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDragStart={dragStart}
      onDragEnd={dragEnd}>
      <GestureView
        style={styles.inner}
        onDoubleTap={props.temporary ? preview : undefined}
        onContextMenu={menu}>
        <Text style={variants.title}>{props.title}</Text>
        <View style={styles.closeOuter} onPress={close}>
          <Image style={styles.close} source={closeImage} />
        </View>
      </GestureView>
    </View>
  );
}

export const styles = {
  root: Styles.View({
    flex: 0,
    flexDirection: 'row',
    height: 42,
    opacity: 0.8,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderColor: Dark.ContentPreviewHeaderTabBorder,
  }),
  dragging: Styles.View({
    opacity: 1,
    backgroundColor: Dark.ContentPreviewHeaderTabDragFill,
  }),
  dropping: Styles.View({
    opacity: 1,
    backgroundColor: Dark.ContentPreviewHeaderTabDropFill,
  }),
  active: Styles.View({
    opacity: 1,
    borderRightWidth: 1,
    backgroundColor: Dark.ContentPreviewHeaderTabActiveFill,
  }),
  touch: Styles.View({
    height: 60,
  }),
  inner: Styles.View({
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginHorizontal: 12,
  }),
  title: Styles.Text({
    fontSize: 16,
    fontWeight: '300',
    lineHeight: 42,
    marginHorizontal: 6,
    color: Dark.ContentPreviewHeaderTabText,
  }),
  titleTouch: Styles.Text({
    fontSize: 20,
    lineHeight: 60,
  }),
  temporary: Styles.Text({
    fontStyle: 'italic',
  }),
  icon: Styles.Image({
    alignSelf: 'center',
    width: 24,
    height: 24,
    marginLeft: 10,
    marginRight: 4,
  }),
  iconFolder: Styles.Image({
    alignSelf: 'center',
    width: 20,
    height: 20,
    marginLeft: 10,
    marginRight: 4,
  }),
  closeOuter: Styles.View({
    justifyContent: 'center',
  }),
  close: Styles.Image({
    alignSelf: 'center',
    width: 16,
    height: 16,
    marginHorizontal: 10,
  }),
};
