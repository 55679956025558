import {DragEvent} from 'react-ult/dist/common/Types';
import {Dispatch} from 'redux';
import {actions} from 'content';

export function onDrop(setDrop: Function) {
  return (e?: DragEvent) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    setDrop(0);
  }
}

export function onDragEnter(setDrop: Function, state: number) {
  return (e?: DragEvent) => {
    e && e.preventDefault();
    e && e.stopPropagation();
    setDrop(state + 1);
  }
}

export function onDragLeave(setDrop: Function, state: number) {
  return (e?: DragEvent) => {
    e && e.stopPropagation();
    setDrop(state - 1);
  }
}

export function onDragStart(setDrag: Function, path: string) {
  return (e?: DragEvent) => {
    setDrag(true);
    if (e) {
      // e.persist();
      e.dataTransfer.effectAllowed = 'move';
      e.dataTransfer.setData('text/uri-list', path);
      e.dataTransfer.setData('text/plain', path);
    }
  }
}

export function onDragEnd(setDrag: Function) {
  return () => {
    console.log('drag end!');
    setDrag(false);
  }
}

export function onSelect(dispatch: Dispatch, path: string) {
  return () => {
    const payload = {paths: [path]};
    dispatch(actions.select({payload}));
  }
}

export function onPreview(dispatch: Dispatch, path: string) {
  return () => {
    const payload = {path};
    dispatch(actions.preview({payload}));
  }
}

export function onFocus(dispatch: Dispatch, index: number) {
  return () => {
    const payload = {index};
    dispatch(actions.focus({payload}));
  }
}

export function onClose(dispatch: Dispatch, index: number) {
  return () => {
    const payload = {index};
    dispatch(actions.close({payload}));
  }
}

export function onNavigate(dispatch: Dispatch, path: string) {
  return () => {
    console.log('navigate', path);
  }
}

export function onMenu(dispatch: Dispatch, path: string) {
  return () => {
    console.log('right clicked', path);
  }
}
