import {App, configureStore, createReducer} from 'react-ult';
import {PRODUCTION} from 'config';

import * as app from 'app';
import * as content from 'content';

import {Store as ContentStore} from 'content/lib/types';
import {Store as AppStore} from 'app/lib/types';

export type Store = {
  app: AppStore;
  content: ContentStore;
}

export const store = configureStore({
  devTools: !PRODUCTION,
  middleware: [],
  reducer: {
    app: createReducer(app.initial,
      Object.fromEntries(Object.keys(app.reducers).map(i =>
        [app.actions[i].type, app.reducers[i]]))),
    content: createReducer(content.initial,
      Object.fromEntries(Object.keys(content.reducers).map(i =>
        [content.actions[i].type, content.reducers[i]]))),
  },
});

App.activationStateChangedEvent.subscribe(state => {
  const payload = {active: state === 1};
  store.dispatch(app.actions.activate({payload}));
});
