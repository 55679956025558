import React, {useCallback} from 'react';
import {View, Styles} from 'react-ult';
import {useDispatch} from 'react-redux';
import {Colors} from 'theme';
import {events} from 'app';
import {ContentFolder} from 'content/ui/ContentFolder';

export function AppFrame() {
  const dispatch = useDispatch();
  const layout = useCallback(events.onLayout(dispatch), [dispatch]);
  return (
    <View style={styles.root} onLayout={layout}>
      <ContentFolder />
    </View>
  );
}

export const styles = {
  root: Styles.View({
    flex: 1,
    flexDirection: 'row',
    backgroundColor: Colors.greyDarkest,
  }),
};
