import {createAction} from 'react-ult';
import {Store} from 'store';
import * as App from 'app/lib/types';
import * as reducers from 'app/lib/reducers';
import * as events from 'app/lib/events';
import * as utils from 'app/lib/utils';

export const initial: App.Store = {
  active: false,
  viewport: {
    width: 1920,
    height: 1080,
  },
};

export const selectors = {
  isActive: (store: Store) => store.app.active,
  getViewport: (store: Store) => store.app.viewport,
};

export const actions = {
  activate: createAction('app/activate', (action: App.Activate) => action),
  resize: createAction('app/resize', (action: App.Resize) => action),
};

export {reducers, events, utils};
