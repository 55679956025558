export function getIcon(type: string) {
  switch (type) {
    case 'folder':
      return require('content/ui/assets/icons/file/folder_yellow.svg');
    case 'image':
      return require('content/ui/assets/icons/seti/image.svg');
    case 'video':
      return require('content/ui/assets/icons/seti/video.svg');
    case 'pdf':
      return require('content/ui/assets/icons/seti/pdf.svg');
    case 'typescript':
      return require('content/ui/assets/icons/seti/typescript.svg');
    case 'lua':
      return require('content/ui/assets/icons/seti/lua.svg');
    default:
      return require('content/ui/assets/icons/seti/default.svg');
  }
}

export function getType(filename: string) {
  return 'unknown';
}

export function getDescription(type: string) {
  return type === 'folder'
    ? '2 folders, 1 file'
    : '4 days ago, 32.93 MB';
}

export function getPath(address: number) {
  // TODO: use succinct trie for paths and compress w/ lz-string
  // http://stevehanov.ca/blog/?id=120
  // https://github.com/siongui/go-succinct-data-structure-trie/blob/master/reference/Bits.js
}
