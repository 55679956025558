import {select, put, call} from 'redux-saga/effects';
import {selectors, actions} from 'content';

export function* focus(action) {
  try {
    const {id} = action.payload;
    const item = yield select(selectors.getItem(id));
    yield call(console.log, id, item);
    yield put(actions.focus(id));
  } catch (error) {
    yield call(console.log, error);
  }
}
