import React from 'react';
import {Styles, View} from 'react-ult';
import {useSelector} from 'react-redux';
import {Dark} from 'theme';
import {selectors} from 'content';
import {ContentListItem} from 'content/ui/ContentListItem';
import {ContentListHeader} from 'content/ui/ContentListHeader';

export interface Props {
  touch: boolean;
  preview: boolean;
}

export function ContentList(props: Props) {
  const path = '#/Events/6F48C323';
  const items = useSelector(selectors.getItems);
  const selection = useSelector(selectors.getSelectList);
  const folder = useSelector(selectors.getView(path));
  const variants = {
    root: [
      styles.root,
      props.preview && styles.showPreview,
    ],
  };

  return (
    <View style={variants.root}>
      <ContentListHeader
        path={path}
        touch={props.touch}
        panel={props.preview}
      />
      <View style={styles.lists}>
        <View style={styles.list}>
          <ContentListItem
            selected
            path="#/Events/6F48C323/Downloads"
            name="app/resize"
            type="folder"
            touch={props.touch}
          />
          <ContentListItem
            selected={selection.indexOf(path) !== -1}
            path="#/Events/6F48C323/Photos"
            name="app/activate"
            type="folder"
            touch={props.touch}
          />
          <ContentListItem
            selected={selection.indexOf(path) !== -1}
            path="#/Events/6F48C323/Videos"
            name="files/navigate"
            type="folder"
            touch={props.touch}
          />
          <ContentListItem
            selected={selection.indexOf(path) !== -1}
            path="#/Events/6F48C323/Music"
            name="files/select"
            type="folder"
            touch={props.touch}
          />
          {/*folder && folder.map((path: string, i: number) =>
            <ContentListItem
              {...items[path]}
              selected={selection.indexOf(path) !== -1}
              key={i}
              touch={props.touch}
            />
          )*/}
        </View>
      </View>
    </View>
  );
}

export const styles = {
  root: Styles.View({
    flex: 1,
  }),
  showPreview: Styles.View({
    flex: 0,
  }),
  lists: Styles.View({
    flex: 1,
    flexDirection: 'row-reverse',
    borderRightWidth: 1,
    borderColor: Dark.ContentListBorder,
  }),
  list: Styles.View({
    flex: 1,
  }),
};
