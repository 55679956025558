import {createAction} from 'react-ult';
import {takeEvery} from 'redux-saga/effects';
import {Store} from 'store';
import {items, views} from 'content/lib/demo';
import * as Content from 'content/lib/types';
import * as reducers from 'content/lib/reducers';
import * as events from 'content/lib/events';
import * as utils from 'content/lib/utils';
import * as sagas from 'content/lib/sagas';

export const initial: Content.Store = {
  preview: null,
  focused: null,
  items,
  views,
  lists: {
    active: [],
    select: [],
    recent: [],
    search: [],
  },
};

export const selectors = {
  getView: (path: string) => (store: Store) => store.content.views[path],
  getItem: (path: string) => (store: Store) => store.content.items[path],
  getItems: (store: Store) => store.content.items,
  getPreviewItem: (store: Store) => store.content.preview,
  getFocusedItem: (store: Store) => store.content.focused,
  getSelectList: (store: Store) => store.content.lists.select,
  getActiveList: (store: Store) => store.content.lists.active,
  getRecentList: (store: Store) => store.content.lists.recent,
  getSearchList: (store: Store) => store.content.lists.search,
};

export const actions = {
  preview: createAction('content/preview', (action: Content.Preview) => action),
  select: createAction('content/select', (action: Content.Select) => action),
  focus: createAction('content/focus', (action: Content.Focus) => action),
  close: createAction('content/close', (action: Content.Close) => action),
};

export const effects = [
  takeEvery(actions.focus.type, sagas.focus),
];

export {reducers, events, utils};
