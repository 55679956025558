import {Platform, UserInterface} from 'react-ult';

const os = Platform.getType();
const isHighPixelDensity = UserInterface.isHighPixelDensityScreen();

export function isWeb() {
  return os === 'web';
}

export function isAndroid() {
  return os === 'android';
}

export function isIOS() {
  return os === 'ios';
}

export function isMacOS() {
  return os === 'macos';
}

export function isWindows() {
  return os === 'windows';
}

export function isNative() {
  return isAndroid() || isIOS() || isMacOS() || isWindows();
}

export function isRetinaScreen() {
  return isHighPixelDensity;
}

export function isTouch() {
  switch (os) {
    case 'android': return true;
    case 'ios': return true;
    case 'macos': return false;
    case 'windows': return false;
    case 'web': return navigator && navigator.maxTouchPoints > 0;
    default: return false;
  }
}

export function getLocale() {
  return os === 'web' ? navigator.language : 'en-US';
}
