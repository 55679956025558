export const Colors = {
  transparent: 'rgba(0, 0, 0, 0)',
  purple: 'rgb(116, 101, 196)',
  purpleDark: 'rgb(73, 66, 113)',
  highlight: 'rgb(255, 240, 204)',
  highlightDark: 'rgb(147, 136, 110)',
  black: 'rgb(0, 0, 0)',
  blackish: 'rgb(15, 15, 15)',
  greyDarkest: 'rgb(30, 30, 30)',  // slate, ashe, charcoal, midnight
  greyDarkerer: 'rgb(40, 40, 40)',
  greyDarker: 'rgb(60, 60, 60)',
  greyDark: 'rgb(80, 80, 80)',
  greyLighterish: 'rgb(100, 100, 100)',
  greyLightish: 'rgb(160, 160, 160)',
  greyLighter: 'rgb(210, 210, 210)',
  greyLight: 'rgb(220, 220, 220)',
  offwhite: 'rgb(230, 230, 230)',
  greyTemp: 'rgb(235, 235, 235)',
  grey: 'rgb(240, 240, 240)',
  whitish: 'rgb(250, 250, 250)',
  white: 'rgb(255, 255, 255)',
};

export const Light = {
  ContentFill: Colors.whitish,
  ContentPreviewFill: Colors.white,
  ContentListFill: Colors.whitish,
  ContentListBorder: Colors.greyLighter,
  ContentListHeaderFill: Colors.purple, // Colors.greyDark,
  ContentListHeaderFolder: Colors.whitish,
  ContentListHeaderArrow: Colors.whitish,
  ContentListItemSelectedFill: Colors.offwhite,
  ContentListItemDropFill: Colors.greyLighter,
  ContentListItemHoverFill: Colors.black,
  ContentListItemTitle: Colors.greyLighterish,
  ContentListItemTitleActive: Colors.blackish,
  ContentListItemDescription: Colors.greyLightish,
  ContentPreviewHeaderFill: Colors.grey,
  ContentPreviewHeaderTabBorder: Colors.greyLighter,
  ContentPreviewHeaderTabActiveFill: Colors.white,
  ContentPreviewHeaderTabDragFill: Colors.white,
  ContentPreviewHeaderTabDropFill: Colors.greyLighter,
  ContentPreviewHeaderTabText: Colors.blackish,
};

export const Dark = {
  ContentFill: Colors.greyDarkest,
  ContentPreviewFill: Colors.blackish,
  ContentListFill: Colors.greyDarkest,
  ContentListBorder: Colors.greyDarker,
  ContentListHeaderFill: Colors.purple, // Colors.greyDarker,
  ContentListHeaderFolder: Colors.whitish,
  ContentListHeaderArrow: Colors.whitish,
  ContentListItemSelectedFill: Colors.greyDark,
  ContentListItemDropFill: Colors.greyDarker,
  ContentListItemHoverFill: Colors.black,
  ContentListItemTitle: Colors.greyLight,
  ContentListItemTitleActive: Colors.whitish,
  ContentListItemDescription: Colors.greyLightish,
  ContentPreviewHeaderFill: Colors.greyDarkest,
  ContentPreviewHeaderTabBorder: Colors.greyDarker,
  ContentPreviewHeaderTabActiveFill: Colors.blackish,
  ContentPreviewHeaderTabDragFill: Colors.greyDark,
  ContentPreviewHeaderTabDropFill: Colors.greyDarker,
  ContentPreviewHeaderTabText: Colors.whitish,
};
