export const views = {
  '#/Events/6F48C323': [
    '#/Events/6F48C323/Downloads',
    '#/Events/6F48C323/Photos',
    '#/Events/6F48C323/Videos',
    '#/Events/6F48C323/Music',
  ],
};

export const items = {
  '#/Events/6F48C323/Downloads': {
    path: '#/Events/6F48C323/Downloads',
    name: 'Untitled',
    type: 'video',
    time: '',
    state: 0,
    rev: 0,
  },
  '#/Events/6F48C323/Photos': {
    path: '#/Events/6F48C323/Photos',
    name: 'Parallel Downloader',
    type: 'video',
    time: '',
    state: 0,
    rev: 0,
  },
  '#/Events/6F48C323/Videos': {
    path: '#/Events/6F48C323/Videos',
    name: 'Unicorn (Desktop)',
    type: 'video',
    time: '',
    state: 0,
    rev: 0,
  },
  '#/Events/6F48C323/Music': {
    path: '#/Events/6F48C323/Music',
    name: 'Unicorn (Mobile)',
    type: 'video',
    time: '',
    state: 0,
    rev: 0,
  },
};
