import React from 'react';
import {Styles, View, Text} from 'react-ult';
import {Dark, Colors} from 'theme';
import {ContentPreviewHeader} from 'content/ui/ContentPreviewHeader';
import {ContentPreviewApp} from 'content/ui/ContentPreviewApp';

export interface Props {
  touch: boolean;
}

export function ContentPreview(props: Props) {
  const variants = {
    root: [
      styles.root,
      props.touch && styles.rootTouch,
    ],
  };

  return (
    <View style={variants.root}>
      <ContentPreviewHeader touch={props.touch} />
      <View style={styles.viewport}>
        <ContentPreviewApp />
        <View style={styles.ruler}></View>
      </View>
      <View style={styles.tools}>
        <View style={styles.tool}>
          CONSOLE
        </View>
        <View style={styles.tool}>
          STATE
        </View>
        <View style={styles.tool}>
          COMPONENTS
        </View>
        <View style={styles.tool}>
          NETWORK
        </View>
      </View>
    </View>
  );
}

export const styles = {
  root: Styles.View({
    flex: 1,
    overflow: 'visible',
    backgroundColor: Dark.ContentPreviewFill,
  }),
  rootTouch: Styles.View({
    backgroundColor: Dark.ContentPreviewFill,
  }),
  viewport: Styles.View({
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  ruler: Styles.View({
    height: 18,
    width: 411,
    backgroundColor: Dark.ContentListBorder,
  }),
  tools: Styles.View({
    flexDirection: 'row',
    height: 36,
    backgroundColor: Dark.ContentFill,
    borderTopWidth: 1,
    borderColor: Dark.ContentListBorder,
  }),
  tool: Styles.Text({
    color: Dark.ContentListItemTitle,
    fontSize: 11,
    lineHeight: 36,
    paddingHorizontal: 16,
  }),
};
