import React, {useState, useCallback} from 'react';
import {Styles, View} from 'react-ult';
import {useSelector} from 'react-redux';
import {Dark} from 'theme';
import {selectors, events, utils} from 'content';
import {ContentPreviewHeaderTab} from 'content/ui/ContentPreviewHeaderTab';

export interface Props {
  touch: boolean;
}

export function ContentPreviewHeader(props: Props) {
  const [dropstate, setDrop] = useState(0);
  const items = useSelector(selectors.getItems);
  const active = useSelector(selectors.getActiveList);
  const focused = useSelector(selectors.getFocusedItem);
  const preview = useSelector(selectors.getPreviewItem);
  const drop = useCallback(events.onDrop(setDrop), [setDrop]);
  const dragEnter = useCallback(events.onDragEnter(setDrop, dropstate), [setDrop, dropstate]);
  const dragLeave = useCallback(events.onDragLeave(setDrop, dropstate), [setDrop, dropstate]);
  const variants = {
    root: [
      styles.root,
      props.touch && styles.touch,
    ],
    spacer: [
      styles.spacer,
      props.touch && styles.touch,
      dropstate > 0 && styles.dropping,
    ],
  };

  return (
    <View style={variants.root}>
      {preview &&
        <ContentPreviewHeaderTab
          temporary
          index={-1}
          touch={props.touch}
          active={focused === -1}
          icon={utils.getIcon(items[preview].type)}
          type={items[preview].type}
          title={items[preview].name}
          path={items[preview].path}
        />
      }
      {active.map((path: string, i: number) =>
        <ContentPreviewHeaderTab
          key={i}
          index={i}
          touch={props.touch}
          active={focused === i}
          icon={utils.getIcon(items[path].type)}
          type={items[path].type}
          title={items[path].name}
          path={items[path].path}
        />
      )}
      <View
        style={variants.spacer}
        onDrop={drop}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}>
      </View>
    </View>
  );
}

export const styles = {
  root: Styles.View({
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 42,
    backgroundColor: Dark.ContentPreviewHeaderFill,
    borderColor: Dark.ContentPreviewHeaderTabBorder,
  }),
  spacer: Styles.View({
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    height: 42,
    borderBottomWidth: 1,
    borderColor: Dark.ContentPreviewHeaderTabBorder,
  }),
  touch: Styles.View({
    height: 60,
  }),
  dropping: Styles.View({
    opacity: 1,
    backgroundColor: Dark.ContentPreviewHeaderTabDropFill,
  }),
};
