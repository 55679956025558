import * as Content from 'content/lib/types';

export function select(state: Content.Store, action: Content.Select) {
  const {paths} = action.payload;
  paths.forEach(path => {
    const active = state.lists.active.indexOf(path);
    if (active === -1) {
      state.preview = path;
      state.focused = -1;
    }
    state.lists.select = paths;
  });
}

export function preview(state: Content.Store, action: Content.Preview) {
  const {path} = action.payload;
  const index = state.lists.active.indexOf(path);
  if (index === -1) {
    if (state.preview === path) {
      state.preview = null;
      state.focused = 0;
      state.lists.active.unshift(path);
    } else {
      state.lists.active.push(path);
      state.focused = state.lists.active.length - 1;
    }
  } else {
    state.focused = index;
  }
}

export function focus(state: Content.Store, action: Content.Focus) {
  const {index} = action.payload;
  if (index === -1 || state.lists.active[index]) {
    state.focused = index;
  }
}

export function close(state: Content.Store, action: Content.Close) {
  const {index} = action.payload;
  const active = state.lists.active.length;
  if (index === -1) {
    state.preview = null;
    state.focused = active > 0 ? 0 : null;
  } else {
    state.lists.active.splice(index, 1);
    if (state.focused && state.focused >= active - 1) {
      state.focused = active - 2;
    }
  }
}
