import React, {useCallback, useState} from 'react';
import {Styles, View, Text} from 'react-ult';
import {Colors, Dark} from 'theme';
import {events} from 'content';

export interface Props {
  title: string;
  touch: boolean;
  active?: boolean;
}

export function ContentListHeaderBreadcrumb(props: Props) {
  const [dropstate, setDrop] = useState(0);
  const drop = useCallback(events.onDrop(setDrop), [setDrop]);
  const dragEnter = useCallback(events.onDragEnter(setDrop, dropstate), [setDrop, dropstate]);
  const dragLeave = useCallback(events.onDragLeave(setDrop, dropstate), [setDrop, dropstate]);
  const variants = {
    title: [
      props.touch ? styles.pathTouch : styles.path,
      props.active && styles.active,
      dropstate > 0 && styles.dropping,
    ]
  };

  return (
    <View
      style={styles.root}
      onDrop={drop}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}>
      <Text style={variants.title}>
        {props.title}
      </Text>
    </View>
  );
}

export const styles = {
  root: Styles.View({
    backgroundColor: Colors.purple,
  }),
  dropping: Styles.View({
    backgroundColor: Colors.purpleDark,
  }),
  path: Styles.Text({
    color: Dark.ContentListHeaderFolder,
    opacity: 0.6,
    fontSize: 16,
    lineHeight: 42,
    paddingHorizontal: 8,
  }),
  pathTouch: Styles.Text({
    color: Dark.ContentListHeaderFolder,
    opacity: 0.6,
    fontSize: 20,
    lineHeight: 60,
    paddingHorizontal: 20,
  }),
  active: Styles.Text({
    opacity: 1,
  }),
};
