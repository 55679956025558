import React from 'react';
import {Styles, View} from 'react-ult';
import {useSelector} from 'react-redux';
import {Dark} from 'theme';
import {ContentPreview} from 'content/ui/ContentPreview';
import {ContentList} from 'content/ui/ContentList';
import * as app from 'app';

export function ContentFolder() {
  const viewport = useSelector(app.selectors.getViewport);
  const showTouch = app.utils.isTouch();
  const showPreview = viewport.width > 700;
  return (
    <View style={styles.root}>
      {showPreview && <ContentPreview touch={showTouch} />}
      <ContentList touch={showTouch} preview={showPreview} />
    </View>
  );
}

export const styles = {
  root: Styles.View({
    flex: 1,
    flexDirection: 'row-reverse',
    backgroundColor: Dark.ContentFill,
  }),
};
