import React from 'react';
import {Fragment, Styles, View, Text} from 'react-ult';
import {Dark} from 'theme';
import {ContentListHeaderBreadcrumb} from 'content/ui/ContentListHeaderBreadcrumb';

export interface Props {
  path: string;
  touch: boolean;
  panel: boolean;
}

export function ContentListHeader(props: Props) {
  const divider = '/';
  const variants = {
    root: [
      styles.root,
      props.touch ? styles.touch : props.panel && styles.mouse,
    ],
    divider: props.touch ? styles.arrowTouch : styles.arrow,
  };

  return (
    <View style={variants.root}>
      <ContentListHeaderBreadcrumb
        active
        touch={props.touch}
        title="0001"
      />
      <Text style={variants.divider}>{divider}</Text>
      <ContentListHeaderBreadcrumb
        touch={props.touch}
        title="2000"
      />
    </View>
  );
}

export const styles = {
  root: Styles.View({
    backgroundColor: Dark.ContentListHeaderFill,
    flex: 0,
    flexDirection: 'row',
    height: 42,
    paddingHorizontal: 4,
    justifyContent: 'flex-end',
  }),
  touch: Styles.View({
    height: 60,
  }),
  mouse: Styles.View({
    width: 240,
  }),
  arrow: Styles.Text({
    color: Dark.ContentListHeaderArrow,
    opacity: 0.6,
    fontSize: 16,
    lineHeight: 42,
  }),
  arrowTouch: Styles.Text({
    color: Dark.ContentListHeaderArrow,
    opacity: 0.6,
    fontSize: 20,
    lineHeight: 60,
  }),
};
